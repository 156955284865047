import React from 'react'
import { graphql } from 'gatsby'
//
import { IntlContext } from 'react-intl'
import { ContentfulTechnique, ContentfulSettings, Site, ContentfulTechniqueConnection } from '~generated/gatsby.types'
import { MainLayout } from '~layout'
import { Tip, ContentCarousel, VotingBlock } from '~components/page-components'
import { buildPageMetaTags } from '~utils'
import { VideoSectionHero, IngredientsEquipmentsListing, StepsListing } from '~components/template-components'
import theme from '~theme'

type PageContextType = {
  id: number
  node_locale: string
}

type PageQuery = {
  site: Site
  contentfulTechnique: ContentfulTechnique
  contentfulSettings: ContentfulSettings
  allContentfulTechnique: ContentfulTechniqueConnection
}

type PageProps = {
  data: PageQuery
  pageContext: PageContextType
}

const Technique = ({ data }: PageProps) => {
  const intl: any = React.useContext(IntlContext)
  const { site, contentfulTechnique, contentfulSettings, allContentfulTechnique } = data
  const {
    id,
    title,
    categories,
    ingredientsEquipment,
    baristaTip,
    videoSection,
    cta,
    steps,
    votingBlock
  } = contentfulTechnique
  const {
    genericTipText,
    genericTipQuote,
    genericTipImage,
    techniqueContentCarouselTitle,
    techniqueContentCarouselShortDescription,
    techniqueContentCarouselCta
  } = contentfulSettings

  const mappedCategories = categories?.filter(cat => cat?.title).map(cat => cat?.id) || []

  const techniques = allContentfulTechnique.edges
    .map(edge => ({
      ...edge.node,
      matches: edge?.node?.categories?.some((category: any) => mappedCategories.includes(category.id)) ? 1 : 0
    }))
    .sort((a, b) => b.matches - a.matches)

  return (
    <MainLayout metaTags={buildPageMetaTags(contentfulTechnique, site)} contentfulSettings={contentfulSettings}>
      <VideoSectionHero
        {...videoSection}
        cta={cta}
        pageTitle={title}
        categoryTitle={intl.formatMessage({ id: 'technique.prefix' })}
      />
      {ingredientsEquipment && (
        <>
          <IngredientsEquipmentsListing
            id={`technique-ingredients-${id}`}
            items={ingredientsEquipment}
            contentfulSettings={contentfulSettings}
            desktopDivider="bottom"
          />
        </>
      )}

      <StepsListing id={`technique-steps-${id}`} steps={steps} />

      <Tip
        {...baristaTip}
        prefix={intl.formatMessage({ id: 'tip.category' })}
        genericTipText={genericTipText}
        genericTipQuote={genericTipQuote}
        genericTipImage={genericTipImage}
      />

      {votingBlock && (
        <VotingBlock
          contentfulSettings={contentfulSettings}
          {...votingBlock}
          bgColorOverride={theme.colors.oatmealBrown}
        />
      )}

      <ContentCarousel
        id={`technique-${id}`}
        title={techniqueContentCarouselTitle}
        shortDescription={techniqueContentCarouselShortDescription}
        contents={techniques}
        cta={techniqueContentCarouselCta}
        bgColorOverride={theme.colors.white}
      />
    </MainLayout>
  )
}

export default Technique

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    contentfulTechnique(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      __typename
      id
      title
      categories {
        title
        id
      }
      videoSection {
        ... on ContentfulVideoSection {
          ...FragmentVideoSection
        }
      }
      cta {
        title
        externalUrl
        content {
          ...FragmentNavigationElement
        }
      }
      shortDescription {
        shortDescription
      }
      ingredientsEquipment {
        ... on ContentfulEquipment {
          ...FragmentEquipment
        }
        ... on ContentfulIngredient {
          ...FragmentIngredient
        }
      }
      steps {
        ... on ContentfulStep {
          ...FragmentStep
        }
      }
      baristaTip {
        ...FragmentTip
      }
      votingBlock {
        ... on ContentfulVotingBlock {
          ...FragmentVotingBlock
        }
      }
      image {
        bynder: bynderAsset {
          fixed(width: 1200, toFormat: JPG, quality: 80) {
            ...QueryImageFixed
          }
        }
        local: localAsset {
          fixed(width: 1200, toFormat: JPG, quality: 80) {
            ...QueryImageFixed
          }
        }
      }
      metaTags {
        title
        description
        noindex
        nofollow
        keywords
        canonical
      }
    }

    allContentfulTechnique(filter: { id: { ne: $id } }, sort: { fields: publishedDate, order: DESC }, limit: 8) {
      edges {
        node {
          __typename
          id
          title
          slug
          shortDescription {
            shortDescription
          }
          categories {
            title
            id
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }

    contentfulSettings(locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    site {
      ...FragmentSite
    }
  }
`
